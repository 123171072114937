import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Technologie a materiály" mdxType="SEO" />
    <h1 {...{
      "id": "technologie"
    }}>{`Technologie`}</h1>
    <p>{`Při výrobě používáme tyto technologie a postupy.`}</p>
    <p><strong parentName="p">{`todo`}</strong>{` - text a ukázka (stroje a postupy ideálně)`}</p>
    <p>{`Dále se můžete podávat na:`}</p>
    <ul>
      <li parentName="ul">{`Používané `}<a parentName="li" {...{
          "href": "materialy/"
        }}>{`materiály`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "parametry/"
        }}>{`Parametry`}</a>{` našich výrobků`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "ukazky/"
        }}>{`Ukázky`}</a></li>
    </ul>
    <hr />
    <a href="javascript:history.back()">Zpět</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      